var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center px-2"},[_c('v-card',{staticClass:"my-4 mx-2 rounded-lg",attrs:{"max-height":"40vh","min-width":"100%","outlined":""},on:{"click":function($event){return _vm.$router.push({
        name: 'inbox.invoice',
        params: { id: _vm.id },
      })}}},[_c('h5',{staticClass:"px-auto mt-8 font-weight-bold text-h5 text-center primary--text"},[_vm._v(" "+_vm._s(_vm.invoiceRef)+" ")]),[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"px-0"},[_c('tr',{staticClass:"px-0"},[_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Category ")]),_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Amount ")]),_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Requester ")])])]),_c('tbody',{staticClass:"px-0"},[_c('tr',{staticClass:"px-0"},[_c('td',{staticClass:"px-0 text-center"},[_vm._v(" "+_vm._s(_vm.category)+" ")]),_c('td',{staticClass:"px-0 text-center"},[_vm._v(_vm._s(_vm.amount))]),_c('td',{staticClass:"px-0 text-center"},[_vm._v(_vm._s(_vm.requester))])])])]},proxy:true}])})],_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"d-flex justify-space-around align-center px-2"},[_c('v-chip',{staticClass:"mx-2 pa-1 text-center",attrs:{"outlined":"","x-small":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.type))])]),(_vm.status)?_c('v-chip',{staticClass:"mx-2 pa-1 text-center",attrs:{"outlined":"","x-small":"","color":_vm.statusIcon}},[_c('span',{staticClass:"text-caption px-0 ma-0"},[_vm._v(_vm._s(_vm.status))])]):_vm._e(),_c('v-chip',{staticClass:"mx-2 py-2",attrs:{"x-small":"","outlined":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.date))])]),[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                      'background-color': hover ? '#2bd5ae' : '',
                      border: hover ? 'none' : '',
                    }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
                        name: 'inbox.invoice',
                        params: { id: _vm.index },
                      })}}},[_vm._v(" view ")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                      'background-color': hover ? '#c4c9cf' : '',
                      border: hover ? 'none' : '',
                      color: hover ? 'white' : '#ffffff',
                    }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                        name: 'inbox.invoice',
                        params: { id: _vm.id },
                        query: { review: true },
                      })}}},[_vm._v("review")])]}}])})],1)],1)],1)]],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }