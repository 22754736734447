var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-6 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center pl-4"},[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.index))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"chiptext",attrs:{"color":_vm.chipcolor,"text-color":_vm.textColor,"x-small":""}},[_vm._v(_vm._s(_vm.type))])],1)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center pl-4"},[_c('span',{staticClass:"mb-0 pl-2 row__text"},[_vm._v(_vm._s(_vm.currency))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 pl-6 pr-4 row__text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.amount,_vm.currencyNoSymbol)))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 pr-2 text-truncate row__text text-truncate",staticStyle:{"max-width":"139px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.invoiceRef))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.invoiceRef))])])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center justify-right"},[_c('span',{staticClass:"mb-0 text-right text-capitalize text-truncate row__text"},[_vm._v(_vm._s(_vm.requester))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.date))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
                    name: 'inbox.invoice',
                    params: { id: _vm.id },
                  })}}},[_vm._v(" view ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                  'background-color': hover ? '#c4c9cf' : '',
                  border: hover ? 'none' : '',
                  color: hover ? 'white' : '#ffffff',
                }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                    name: 'inbox.invoice',
                    params: { id: _vm.id },
                    query: { review: true },
                  })}}},[_vm._v("review")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }