<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md1>
            <div class="d-flex align-center pl-4">
              <span class="mb-0 row__text">{{ index }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <v-chip
                :color="chipcolor"
                :text-color="textColor"
                class="chiptext"
                x-small
                >{{ type }}</v-chip
              >
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center pl-4">
              <span class="mb-0 pl-2 row__text">{{ currency }}</span>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 pl-6 pr-4 row__text">{{
                amount | currency(currencyNoSymbol)
              }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="mb-0 pr-2 text-truncate row__text text-truncate"
                    style="max-width: 139px"
                    >{{ invoiceRef }}</span
                  >
                </template>
                <span>{{ invoiceRef }}</span>
              </v-tooltip>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center justify-right">
              <span
                class="mb-0 text-right text-capitalize text-truncate row__text"
                >{{ requester }}</span
              >
            </div>
          </v-flex>
          <v-flex md1>
            <div>
              <span class="mb-0 row__text">{{ date }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center justify-center">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="
                    $router.push({
                      name: 'inbox.invoice',
                      params: { id: id },
                    })
                  "
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  exact-path
                  depressed
                  outlined
                  dark
                  small
                  color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded"
                  style="color: var(--v-primary-base)"
                >
                  view
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="
                    $router.push({
                      name: 'inbox.invoice',
                      params: { id: id },
                      query: { review: true },
                    })
                  "
                  dark
                  outlined
                  depressed
                  small
                  color="primary"
                  :style="{
                    'background-color': hover ? '#c4c9cf' : '',
                    border: hover ? 'none' : '',
                    color: hover ? 'white' : '#ffffff',
                  }"
                  class="text-capitalize px-2 my-1 rounded"
                  >review</v-btn
                >
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";
export default {
  name: "DataTable",
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },

    type: {
      type: String,
    },
    amount: {
      type: String,
    },
    category: {
      type: String,
    },
    invoiceRef: {
      type: String,
    },
    requester: {
      type: String,
      default: "",
    },
    date: {
      type: String,
    },
    status: {
      type: String,
    },
    textColor: {
      type: String,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      // currencyFilter: {
      //   symbol: "",
      //   thousandsSeparator: ",",
      //   fractionCount: 2,
      //   fractionSeparator: ".",
      //   symbolPosition: "front",
      //   symbolSpacing: false,
      //   avoidEmptyDecimals: "",
      // },
    };
  },
  mixins: [currencyFilter],
  methods: {},
  computed: {
    statusIcon() {
      if (this.status === "pending") {
        return "#FF6A6A";
      } else if (this.status === "processing") {
        return "#F7CA40";
      } else if (this.status === "in review") {
        return "#2B99D5";
      } else return "#2BD5AE";
    },
    chipcolor() {
      if (this.type === "customer") {
        return "#F9EED2";
      } else return "#D5F7EF";
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

.row__text {
  font-family: "inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}

.chiptext {
  font-size: 0.65rem;
  font-weight: 600;
}
</style>
